<!-- 餐厅配置-门店商品 -->

<template>
  <div class="store-products main-cnt">
    <div class="title">商品列表</div>
    <div class="content">
      <common-table ref="commodiTable" tableHeight="calc(100vh - 325px)" :apiName="DinnerApi.getDinnerGoodList"
        :columns="tableColumns" @edit="onEditBtn" @statusChange="statusChange" @onAtlasBtn="onAtlasBtn">
        <template #operate>
          <el-button type="primary" @click="showAddDialog"
            v-if="authData.indexOf('r_BRFaEHoVgETw1gBnZMJZFbqp7B1H') != -1">
            新增商品
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增、编辑 -->
    <w-dialog ref="commodiDialog" class="commodi-dialog" :title="isEdit ? '编辑商品' : '新增商品'" width="55%" btnWidth="140px"
      top="3vh" :confirmText="isEdit ? '确认保存' : '确认新增'" @wConfirm="commodiConfirm">
      <el-form class="dining-form" ref="formRef" :model="commodiForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="所属门店" prop="dr_id">
              <el-select v-model="commodiForm.dr_id" clearable placeholder="请选择所属门店" @change="storeChange">
                <el-option :label="item.dr_name" :value="item.dr_id" v-for="item in storeOptions" :key="item.dr_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <w-title :title_name="'商品信息'"></w-title>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品名称" prop="drg_name">
              <el-input v-model="commodiForm.drg_name" clearable placeholder="请输入商品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品单价" prop="drg_price">
              <el-input v-model="commodiForm.drg_price" clearable placeholder="请输入商品单价"
                oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品单位" prop="drg_unit">
              <el-input v-model="commodiForm.drg_unit" clearable placeholder="请输入商品单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品分类" prop="gtids">
              <el-select v-model="commodiForm.gtids" clearable multiple collapse-tags placeholder="请选择商品分类">
                <el-option :label="item.drgt_title" :value="item.drgt_id" v-for="item in separateOptions"
                  :key="item.drgt_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div class="flex-btw align-center">
              <w-title :title_name="'商品规格'"></w-title>
              <el-button type="primary" plain @click="onAddSpecification('1')" class="add-specif-btn">添加规格</el-button>
            </div>
          </el-col>
          <el-col :span="24" v-for="(item, index) in prodSpec" :key="index">
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="flex-btw spec-title">
                  <div>规格{{ chineseNumbers[index] }}</div>
                  <div class="flex">
                    <div class="text-blue cursor-pointer" @click="onAddSpecification('2', index)">增加</div>
                    <div class="red-blue cursor-pointer s-m-l" @click="onAddSpecification('3', index)"
                      v-if="isEdit && index !== 0">删除</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="8">
                <el-input v-model="item.name" clearable placeholder="请输入规格名称"></el-input>
              </el-col>
              <el-col :span="16">
                <div class="flex" style="flex-wrap: wrap;">
                  <div style="width: 157px;" class="s-m-l s-m-b" v-for="(el, s) in item.specific" :key="s">
                    <el-input v-model="el.text" clearable placeholder="请输入具体规格"></el-input>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="24">
            <w-title :title_name="'商品图册'"></w-title>
          </el-col>
          <el-col :span="24">
            <el-form-item label="封面图片">
              <img-upload :limit="1" :fileList="fileList" @uploadFile="uploadFile" @deleteFile="deleteFile"
                suggestText=" "></img-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详情图片">
              <img-upload :limit="99" :fileList="detailedImages" @uploadFile="uploadDetailedImages"
                @deleteFile="deleteDetailedImages" suggestText=" ">
              </img-upload>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div class="flex align-center">
              <w-title :title_name="'打包费用'"></w-title>
              <el-switch v-model="commodiForm.isFight" class="s-m-l" @change="switchChange" />
            </div>
          </el-col>

          <el-col :span="8" v-for="(item, i) in bagConfDatas" :key="i" v-if="commodiForm.isFight">
            <el-form-item :label="item.drgb_name + '(' + item.drgb_price + '/个)'">
              <el-input v-model="item.drg_bag_number" clearable placeholder="请输入需要个数" @blur="packNumber(i)">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>

    <!-- 图册 -->
    <w-dialog ref="atlasDialog" class="atlas-dialog" title="图册" width="41%" btnWidth="140px" top="25vh"
      :hideFooter="true">
      <!-- 封面 -->
      <div class="s-m-b">
        <div class="atlas-title">封面图片</div>
        <div class="flex flex-wrap">
          <div v-for="(item, i) in atlasDatas.c_file" :key="i">
            <el-image style="width: 80px; height: 80px" :src="item.drgf_path_text"
              :preview-src-list="atlasDatas.c_file.map((obj) => obj.drgf_path_text)" fit="cover">
            </el-image>
          </div>
        </div>
      </div>

      <!-- 详情 -->
      <div>
        <div class="atlas-title">详情图片</div>
        <div class="flex flex-wrap">
          <div v-for="(item, i) in atlasDatas.d_file" :key="i" class="s-m-r">
            <el-image style="width: 80px; height: 80px" :src="item.drgf_path_text"
              :preview-src-list="atlasDatas.d_file.map((obj) => obj.drgf_path_text)" fit="cover">
            </el-image>
          </div>
        </div>
      </div>
    </w-dialog>
  </div>
</template>
<script setup>
  import { ref, reactive, onMounted, computed, watch, nextTick, } from "vue";
  import { DinnerApi, } from "@/plugins/api.js";
  import { ElMessage, } from "element-plus";
  import { useStore } from "vuex";
  import imgUpload from "@/components/img-upload/img-upload.vue";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "drg_name",
      label: "商品名称",
    },
    {
      prop: "dr_name",
      label: "所属门店",
    },
    {
      prop: "drg_type",
      label: "商品分类",
    },
    {
      prop: "drg_price",
      label: "商品单价",
    },
    {
      prop: "drg_unit",
      label: "商品单位",
    },
    {
      type: "block",
      prop: "goods_file_count",
      label: "图册",
      active: "onAtlasBtn",
      minWidth: 60,
      token: "r_bm5kZaxStEEADHI9I6yFrPCkiJhw",
    },
    {
      type: "switch",
      prop: "drg_status",
      label: "状态",
      activeText: ' ',
      inactiveText: ' ',
      minWidth: 80,
      token: "r_wJKVCng7lFuw9IDfvINpBVOW7nz3",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 100,
      bottons: [
        {
          name: "编辑",
          action: "edit",
          token: "r_DG3NemxZu0kS6PyPJ036NeliEoys",
          className: "theme-font-btn",
        },
      ],
    },
  ]);

  const commodiTable = ref(null);  // 列表表格数据
  const chineseNumbers = ref(['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十',]);
  const prodSpec = ref([]);  // 商品规格
  const storeOptions = ref([]);  // 所属门店选项
  const separateOptions = ref([]);  // 商品分类选项
  const bagConfDatas = ref([]);  // 打包配置
  const isEdit = ref(false);  // 是否新增
  const commodiDialog = ref(null);  // 新增、编辑弹框
  const formRef = ref(null);  // 表单对象
  const currentRow = ref(null);  // 当前操作行数据
  const commodiForm = reactive({  // 表单数据对象
    dr_id: '', // 所属门店id
    drg_name: '', // 商品名称
    drg_price: '', // 商品单价
    drg_unit: '',  // 商品单位
    gtids: [],  // 分类id
    isFight: false,  // 是否打包
  });
  const rules = reactive({  // 表单规则
    dr_id: [
      { required: true, message: "请选择所属门店", trigger: "change", },
    ],
    drg_name: [
      { required: true, message: "请输入商品名称", trigger: "blur", },
    ],
    drg_price: [
      { required: true, message: "请输入商品单价", trigger: "blur", },
    ],
    drg_unit: [
      { required: true, message: "请输入商品单位", trigger: "blur", },
    ],
    gtids: [
      { required: true, message: "请选择商品分类", trigger: "change", },
    ],
  });
  const fileList = ref([]);  // 封面文件
  const detailedImages = ref([]);  // 详情文件
  const atlasDialog = ref(null);  // 图册对象
  const atlasDatas = ref([]);  // 图册数据
  /**
   * 
   * 获取门店选项
   * 
   * **/
  const getStoreData = () => {
    DinnerApi.getRangDinnerRoom().then((res) => {
      if (res.Code === 200) {
        storeOptions.value = res.Data ? res.Data : [];
        // 如果只有单个则设置默认
        if (storeOptions.value.length == 1) {
          commodiForm.dr_id = res.Data[0].dr_id;
          storeChange();
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 获得打包盒配置
   * 
   * **/
  const getBagConf = () => {
    DinnerApi.getBagConf().then((res) => {
      if (res.Code === 200) {
        bagConfDatas.value = res.Data;
        if (bagConfDatas.value.length > 0) {
          bagConfDatas.value.forEach(item => {
            item.drg_bag_number = '';
          })
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 新增按钮
   * 
   * **/
  const showAddDialog = () => {
    getBagConf();
    isEdit.value = false;
    prodSpec.value = [];
    commodiForm.dr_id = '';
    commodiForm.drg_name = '';
    commodiForm.drg_price = '';
    commodiForm.drg_unit = '';
    commodiForm.gtids = [];
    commodiForm.isFight = false;
    separateOptions.value = [];
    prodSpec.value.push({
      name: '',  // 规格名
      specific: [{ text: '' },],  // 具体规格
    });
    fileList.value = [];
    detailedImages.value = [];
    store.dispatch("getQiniuData");
    getStoreData();
    commodiDialog.value.show();
  };
  /**
   * 
   * 编辑按钮
   * 
   * **/
  const onEditBtn = (row) => {
    getBagConf();
    commodiForm.isFight = false;
    currentRow.value = row;
    isEdit.value = true;
    prodSpec.value = [];
    fileList.value = [];
    detailedImages.value = [];
    getStoreData();
    nextTick(() => {
      getStoreInfo();
    });
    store.dispatch("getQiniuData");
    commodiDialog.value.show();
  };
  /**
   * 
   * 添加规格按钮
   * 
   * **/
  const onAddSpecification = (type, i) => {
    if (type == '1') {
      // 添加规格
      prodSpec.value.push({
        name: '',  // 规格名
        specific: [{ text: '' },],  // 具体规格
      });
    } else if (type == '2') {
      // 添加具体规格
      prodSpec.value[i].specific.push({ text: '' });
    } else if (type == '3') {
      // 删除规格
      prodSpec.value.splice(i, 1);
    }
  }
  /**
   * 
   * 封面文件上传
   * 
   * **/
  const uploadFile = (data) => {
    fileList.value.push({ file_url: data.standard_url, key: data.key });
  };
  /**
   * 
   * 封面文件删除
   * 
   * **/
  const deleteFile = (data) => {
    let index1 = fileList.value.findIndex((item) => item.key === data.key);
    fileList.value.splice(index1, 1);
  };
  /**
   * 
   * 详情文件上传
   * 
   * **/
  const uploadDetailedImages = (data) => {
    detailedImages.value.push({ file_url: data.standard_url, key: data.key });
  };
  /**
   * 
   * 详情文件删除
   * 
   * **/
  const deleteDetailedImages = (data) => {
    let index1 = detailedImages.value.findIndex((item) => item.key === data.key);
    detailedImages.value.splice(index1, 1);
  };
  /**
   * 
   * 打包费用开关
   * 
   * **/
  const switchChange = () => {
    // 清空已填内容
    bagConfDatas.value.forEach(item => {
      item.drg_bag_number = '';
    })
  }
  /**
   * 
   * 填写打包盒个数
   * 
   * **/
  const packNumber = (i) => {
    // 清空其他输入框内容
    bagConfDatas.value.forEach(item => {
      if (bagConfDatas.value[i].drgb_id == item.drgb_id) {

      } else {
        item.drg_bag_number = '';
      }
    })
  }
  /**
   * 
   * 获取详情
   * 
   * **/
  const getStoreInfo = () => {
    DinnerApi.getStoreInfo({ drg_id: currentRow.value.drg_id }).then((res) => {
      if (res.Code === 200) {
        // console.log("详情", res);
        commodiForm.dr_id = res.Data.drg_dr_id;
        commodiForm.drg_name = res.Data.drg_name;
        commodiForm.drg_price = res.Data.drg_price;
        commodiForm.drg_unit = res.Data.drg_unit;
        // 附件
        if (res.Data.c_file.length > 0) {
          res.Data.c_file.forEach(item => {
            fileList.value.push({
              file_url: item.standard_url,
              key: item.key,
            });
          })
        }
        if (res.Data.d_file.length > 0) {
          res.Data.d_file.forEach(item => {
            detailedImages.value.push({
              file_url: item.standard_url,
              key: item.key,
            });
          })
        }
        storeChange();
        // 商品分类
        if (res.Data.type.length > 0) {
          res.Data.type.forEach(item => {
            commodiForm.gtids.push(item.drgt_id);
          })
        }
        // 商品规格
        if (res.Data.spe.length > 0) {
          res.Data.spe.forEach(item => {
            item.specific = [];
            item.value.forEach(el => {
              item.specific.push({ text: el });
            })
            prodSpec.value.push(item);
          })
        } else {
          prodSpec.value.push({
            name: '',  // 规格名
            specific: [{ text: '' },],  // 具体规格
          });
        }
        // 打包费用
        if (res.Data.drg_bag_id) {
          commodiForm.isFight = true;
          bagConfDatas.value.forEach(item => {
            if (item.drgb_id == res.Data.drg_bag_id) {
              item.drg_bag_number = res.Data.drg_bag_number;
            }
          })
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 选择门店获取关联商品
   * 
   * **/
  const storeChange = () => {
    commodiForm.gtids = [];
    separateOptions.value = [];
    DinnerApi.getTypebydrid({ dr_id: commodiForm.dr_id }).then((res) => {
      if (res.Code === 200) {
        separateOptions.value = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
  * 
  * 新增、编辑确认
  * 
  * **/
  const commodiConfirm = () => {
    formRef.value.validate((valid) => {
      if (valid) {
        let specArr = [];
        // 商品规格
        if (prodSpec.value.length > 0) {
          prodSpec.value.forEach(item => {
            if (item.name) {
              item.childArr = [];
              item.specific.forEach(el => {
                if (el.text) {
                  item.childArr.push(el.text);
                }
              })
              specArr.push({
                name: item.name,
                value: item.childArr.join(","),
              });
            }
          })
        }
        let params = {
          dr_id: commodiForm.dr_id,
          drg_name: commodiForm.drg_name,
          drg_price: commodiForm.drg_price,
          drg_unit: commodiForm.drg_unit,
          gtids: commodiForm.gtids.join(","),
          d_file: [],
          c_file: [],
          spe: JSON.stringify(specArr),
          drgb_id: '',
          drg_bag_number: '',
        };
        let url = '';
        // 详情文件
        if (detailedImages.value.length > 0) {
          detailedImages.value.forEach(item => {
            params.d_file.push(item.key);
          })
          params.d_file = JSON.stringify(params.d_file);
        } else {
          ElMessage.error("请上传详情文件！");
          return false;
        }
        // 封面文件
        if (fileList.value.length > 0) {
          fileList.value.forEach(item => {
            params.c_file.push(item.key);
          })
          params.c_file = JSON.stringify(params.c_file);
        } else {
          ElMessage.error("请上传封面文件！");
          return false;
        }
        // 打包费用
        if (commodiForm.isFight) {
          bagConfDatas.value.forEach(item => {
            if (item.drg_bag_number) {
              params.drgb_id = item.drgb_id;
              params.drg_bag_number = item.drg_bag_number;
            }
          })

          if (!params.drgb_id) {
            ElMessage.error("请输入打包盒个数！");
            return false;
          }
        } else {
          params.drgb_id = '';
          params.drg_bag_number = '';
        }
        if (isEdit.value) {
          // 编辑
          url = 'updateStoreProducts';
          params.drg_id = currentRow.value.drg_id;
        } else {
          // 新增
          url = 'addStoreProducts';
        }
        // console.log("提交", url, params);
        commodiDialog.value.isLoading = true;
        DinnerApi[url](params).then((res) => {
          commodiDialog.value.isLoading = false;
          let text = isEdit.value ? "编辑" : "新增";
          if (res.Code === 200) {
            ElMessage.success(`${text}成功！`);
            commodiDialog.value.close();
            commodiTable.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
    })
  };
  /**
  * 
  * 修改状态
  * 
  * **/
  const statusChange = (row) => {
    DinnerApi.updateStoreStatus({
      drg_id: row.drg_id,
      drg_status: row.drg_status == 1 ? 2 : 1,
    }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("修改成功！");
        commodiTable.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
  * 
  * 图册
  * 
  * **/
  const onAtlasBtn = (row) => {
    DinnerApi.getGoodFile({ drg_id: row.drg_id, }).then((res) => {
      if (res.Code === 200) {
        atlasDatas.value = res.Data;
        atlasDialog.value.show();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    commodiTable.value.tableLoad();
  });
</script>

<style lang="scss">
  .store-products {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .s-m-l {
      margin-left: 10px;
    }

    .s-m-r {
      margin-right: 10px;
    }

    .s-m-b {
      margin-bottom: 10px;
    }

    .spec-title {
      padding: 10px 0px 10px 0;
    }

    .text-blue {
      color: var(--theme-color);
    }

    .red-blue {
      color: var(--error-color);
    }

    .commodi-dialog {

      .add-specif-btn {
        width: 80px;
        padding: 0;
        border: 1px solid var(--theme-color);
        background-color: var(--search-bg-color);
        min-height: 32px;
        max-height: 32px;
      }

      .el-dialog {
        min-width: 840px;

        .el-dialog__body {
          padding: 15px 15px 40px;

          .dining-form {
            .el-form-item {
              .el-form-item__content {
                .el-date-editor {
                  width: 100%;

                  .el-range-input {
                    background-color: transparent;
                  }
                }
              }
            }

            .el-form-item.block {
              width: 100%;
            }

            .el-divider {
              margin: 30px 0 0 15px;
              width: calc(100% - 30px);
            }
          }
        }
      }
    }

    .atlas-title {
      color: var(--text-color);
      padding: 10px 0;
      font-size: 14px;
      font-weight: bold;
    }
  }
</style>